import { FC, ReactNode } from 'react';

export type AuthSlideoutItemProps = {
  title: string;
  description: string;
  icon: ReactNode;
};

export const AuthSlideoutItem: FC<AuthSlideoutItemProps> = ({ title, description, icon }) => (
  <div className='d-flex flex-lg-column riforma-regular px-lg-3 my-3 text-lg-center'>
    <div className='pe-3 d-flex mb-lg-2 justify-content-center'>
      <div
        className='d-flex align-items-center text-success justify-content-center rounded-3 overflow-hidden'
        style={{ backgroundColor: '#f5f9f8', height: '32px', width: '32px' }}
      >
        {icon}
      </div>
    </div>
    <div>
      <div className='fs-16px fs-lg-18px line-height-24px line-height-lg-32px mb-2 riforma-medium'>{title}</div>
      <div className='fs-12px fs-lg-14px line-height-24px'>{description}</div>
    </div>
  </div>
);
