import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Autoplay, Lazy, Navigation, Pagination, Virtual } from 'swiper';
import { SwiperProps } from 'swiper/react/swiper-react';
import { SwiperOptions } from 'swiper/types';

import carouselStyles from 'styles/Carousel.module.scss';

export interface ArrowClassname {
  left: string;
  right: string;
}

export type CarouselProps = PropsWithChildren & {
  slidesPerView?: number | 'auto';
  title?: React.ReactNode;
  swiperProps?: SwiperProps;
  arrowClassname?: ArrowClassname;
  showArrowGradient?: boolean;
  disableArrows?: boolean;
  virtual?: boolean;
  className?: string;
};

export const defaultSwiperProps: SwiperOptions = {
  spaceBetween: 10,
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  slidesPerGroupAuto: true
};

export const defaultPreRenderedVirtualSlidesAmount = 2;

export const defaultArrowClassname: ArrowClassname = {
  left: classNames('position-relative', carouselStyles['arrow-left']),
  right: classNames('position-relative', carouselStyles['arrow-right'])
};

export const swiperModules = [Pagination, Autoplay, Virtual, Navigation, Lazy];
