import { FC } from 'react';
import { AiOutlineRise } from '@react-icons/all-files/ai/AiOutlineRise';
import { BsHeart } from '@react-icons/all-files/bs/BsHeart';
import { FaDollarSign } from '@react-icons/all-files/fa6/FaDollarSign';
import { useRouter } from 'next/router';

import { AuthSlideoutItem } from 'components/common/AuthSlideout';
import { Slideout, SlideoutCommonProps, SubmitButtonWithArrow } from 'components/design-system/Slideout';
import { intercomShowNewMessage } from 'lib/intercom';
import { pageRoutes } from 'lib/routes';

import { AuthLogo } from './AuthLogo';

export const SellerAuthSlideout: FC<SlideoutCommonProps> = ({ ...props }) => {
  const router = useRouter();
  const onRedirectToSeller = () => {
    router.push(pageRoutes.sellerOnboarding);
    props.closeModal?.();
  };

  return (
    <Slideout
      title
      submit={{
        textClassname: 'w-100',
        onClick: onRedirectToSeller,
        title: <SubmitButtonWithArrow text='Become a Bezel seller' />
      }}
      cancel={{ title: 'Ask us a question', onClick: intercomShowNewMessage }}
      {...props}
    >
      <div className='d-flex flex-column overflow-auto'>
        <AuthLogo />
        <div className='text-center fs-40px riforma-regular line-height-48px'>
          You need to be a <span className='text-success'>Bezel seller</span> to do that
        </div>
        <AuthSlideoutItem
          title='Easy listing and tracking'
          description='Sell and manage your watches with ease, getting the most value out of your collection has never been easier.'
          icon={<BsHeart />}
        />
        <AuthSlideoutItem
          title='Transparent levels and fees'
          description='As you sell more items, your fee drops substantially. All of our payouts are wildly transparent with no hidden fees.'
          icon={<AiOutlineRise size={20} />}
        />
        <AuthSlideoutItem
          title='Quick and easy payment'
          description='The average time from sale to payout is 5 business days, the fastest in the industry.'
          icon={<FaDollarSign />}
        />
      </div>
    </Slideout>
  );
};
